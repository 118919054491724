import React from "react"
import { Card, Col } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import useSitedata from "./useSitedata"
import { GiPencilBrush } from "@react-icons/all-files/gi/GiPencilBrush"
import { AiFillBulb } from "@react-icons/all-files/ai/AiFillBulb"
import { AiOutlineBulb } from "@react-icons/all-files/ai/AiOutlineBulb"

export default function Cardcomp(cardData) {
  const compCardData = cardData.cardDataProp
  const compRequestFrom = cardData.compAddedIn
  const artworksPageName = useSitedata("artworksPageName")
  const projectsPageName = useSitedata("projectsPageName")

  let toValue = ""
  let customTitle = ""

  if (compRequestFrom === artworksPageName) {
    toValue = `/${artworksPageName}/${compCardData.slug}`
  } else if (compRequestFrom === projectsPageName) {
    toValue = `/${projectsPageName}/${compCardData.slug}`
  } else if (
    compRequestFrom === "index" + artworksPageName ||
    compRequestFrom === "index" + projectsPageName
  ) {
    // compAddedIn data from index shall be in the form indexstore or indexprojects
    toValue = `/${compRequestFrom.substr(5)}`
    // compCardData.title=`Explore ${toValue.slice(1)}`
    customTitle = `Explore ${toValue.slice(1)}`
  }

  let cardElement = ""

  if (
    compRequestFrom === "index" + artworksPageName ||
    compRequestFrom === "index" + projectsPageName
  ) {
    cardElement = (
      <Col lg={4} md={6} sm={12} className="mb-3">
        <Link
          to={toValue}
          key={compCardData.id}
          style={{ textDecoration: "none" }}
        >
          {/* <Card bg="light" border="info" className="h-100 text-dark"> */}
          <Card bg="light" className="h-100 text-dark">
            <GatsbyImage
              image={compCardData.image.childImageSharp.gatsbyImageData}
              alt={customTitle}
              className="card-image-top"
            />
            <Card.Body>
              <Card.Title>{customTitle}</Card.Title>
            </Card.Body>
          </Card>
        </Link>
      </Col>
    )
  } else {
    // const mdate = new Date(compCardData.year, compCardData.month - 1, 1)
    // const monthName = mdate.toLocaleString("default", { month: "long" })
    // const projectDate = `${monthName} ${compCardData.year}`

    let availability = ""
    if (compCardData.print === 1 && compCardData.original === 1) {
      availability = "Original & Print available"
    } else if (compCardData.print === 1 && compCardData.original === 0) {
      availability = "Print available"
    } else if (compCardData.print === 0 && compCardData.original === 1) {
      availability = "Original available"
    } else {
      availability = "For reference only"
    }

    const cardText2 = availability

    let cardText1 = ""
    if (compCardData.surface === null) {
      cardText1 = compCardData.medium
    } else {
      cardText1 = `${compCardData.medium} on ${compCardData.surface}`
    }

    const bulbColor="rgb(255,0,0)"

    cardElement = (
      <Col lg={4} md={6} sm={12} className="mb-3">
        <Link
          to={toValue}
          key={compCardData.id}
          style={{ textDecoration: "none" }}
        >
          <Card bg="light" className="h-100 text-dark">
            <GatsbyImage
              image={compCardData.image.childImageSharp.gatsbyImageData}
              alt={compCardData.title}
              className="card-image-top"
              // loading="eager"
            />
            <Card.Body>
              <Card.Title>{compCardData.title}</Card.Title>
              <Card.Text>
                {/* <p>{projectDate}</p> */}
                {/* GiPencilBrush */}
                <p className="text-muted">
                  <span style={{ color: "rgb(0,124,165)", fontSize: "1.2em" }}>
                    <GiPencilBrush />
                  </span>{" "}
                  <small>{cardText1}</small>
                  <br />
                  {(availability === "For reference only")?<span style={{ color: "rgb(190,190,190)", fontSize: "1.2em" }}><AiOutlineBulb /></span>:<span style={{ color: "rgb(50,150,50)", fontSize: "1.2em" }}><AiFillBulb /></span>}
                  
                  <small>{cardText2}</small>
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
      </Col>
    )
  }

  return cardElement
}
