import React from "react"
import Layout from "../components/Layout"
import useFetch from "../components/useFetch"
import { Container, Row, Col } from "react-bootstrap"
import * as styles from "../styles/home.module.css"
import Cardcomp from "../components/Cardcomp"
import useSitedata from "../components/useSitedata"
import Headmeta from "../components/Headmeta"
import { Link } from "gatsby"

export default function Index() {
  // const projectsDataString = useFetch('projects');
  // const projects = JSON.parse(projectsDataString);
  const projectsPageName = useSitedata("projectsPageName")
  const projects = useFetch(projectsPageName)
  // console.dir(projects);

  return (
    <Layout>
      <Headmeta
        importedIn={`${projectsPageName} | Explore our truly customized projects`}
        importedUrl="projects"
      />
      <div>
        <Container className={styles.homeStyles}>
          <Row className="text-center">
            <Col>
              <h1 className="display-5 pt-5">
                <span style={{ color: "rgb(195,98,65)" }}>Zero to One</span>{" "}
                <br /> because it elates us
                {/* <span style={{ color: "rgb(195,98,65)" }}>Zero to One</span> <br /> because projects make you feel it */}
              </h1>
              <p className="pb-5">
                The projects listed below are varied in nature. From Calendar to
                wedding card and more. Explore these different projects and
                please{" "}
                <Link to="/contact" className="text-primary">
                  contact us
                </Link>{" "}
                for orders or any other query.
              </p>
            </Col>
          </Row>
          <Row className="text-center justify-content-center">
            {projects.map(project => (
              <Cardcomp
                cardDataProp={project.frontmatter}
                compAddedIn={projectsPageName}
              />
            ))}
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
