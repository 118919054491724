
import { useStaticQuery,graphql } from "gatsby"
import useSitedata from "./useSitedata"

// this code is used to separate project md file data stores md file data
// this data shall be used separately in store.js and projects.js

const useFetch=(argData)=>{

  // const artworksPageName = useSitedata("artworksPageName")
  const projectsPageName = useSitedata("projectsPageName")
  // console.log('useFetch: ',argData,projectsPageName)

    // first get the data using grahiql query
    const queryData=useStaticQuery(graphql`
    query fetchPage {
        fetched: allMarkdownRemark(sort: {fields: frontmatter___year, order: DESC}) {
          nodes {
            frontmatter {
              title
              slug
              month
              year
              surface
              original
              print
              medium
              image{
                childImageSharp {
                  gatsbyImageData(
                    backgroundColor: "transparent"
                    layout:CONSTRAINED
                    placeholder:BLURRED
                    aspectRatio: 1.5
                    transformOptions: {fit: CONTAIN}      
                    )
                }
              }
            }
            id
          }
        }
      }
      `
    )

    // since we have two types of md files: projects and store
    // but the data that we are getting using above query is for all md files
    // we need to separate the data for projects and store
    // so we will use the following code to do that
    // we will create two arrays for projects and store

    // console.dir(queryData)
      const dataArray=queryData.fetched.nodes
      // console.dir(dataArray)

      let projectsArray=[];
      let storeArray=[];

      dataArray.forEach(function(item){
        let slugVal=item.frontmatter.slug
    
        if(slugVal.slice(4,5)==='Z'){ // if the slug contains PR, then it is projects related data
        projectsArray.push(item)
        }
        else{ //else it is store data
          storeArray.push(item);
        }
      })

    let mydata='';

    if (argData===projectsPageName){
        // mydata=JSON.stringify(projectsArray);
        mydata=projectsArray;
    }
    else{
        // mydata=JSON.stringify(storeArray);
        mydata=storeArray;
    }
    // console.log(mydata);
    return mydata;
}

export default useFetch;